import React from "react"

import {
  MainBanner,
  Welcome,
  Features,
  ContactUs,
  SubscribeNewsletter,
} from "../components/homePage"
import SEO from "../components/SEO"

const Home = () => {
  return (
    <>
      <SEO title="Creativehub301" />
      <MainBanner />
      <Welcome />
      <Features />
      <ContactUs />
      <SubscribeNewsletter />
    </>
  )
}

export default Home
